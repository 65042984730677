import { createContext, useState, useEffect } from "react";
import { attachPromocodeToBasket, removePromocodeFromBasket, getAttachedToBasketPromocode } from "../functions/fetch";
import { useTranslation } from "react-i18next";

export const PromocodeContext = createContext();

const defaultPromocodeInfo = {code: '', state: ''};

export const PromocodeProvider = ({children}) => {
    const {t} = useTranslation()
    const [promocode, setPromocode] = useState(structuredClone(defaultPromocodeInfo));
    const [appliedPromocode, setAppliedPromocode] = useState(null)

    const fetchAttachedPromocode = async () => {
        const fetchedPromocode = await getAttachedToBasketPromocode();
        if(fetchedPromocode) {
            displayAttachedPromocode(structuredClone(fetchedPromocode), structuredClone(defaultPromocodeInfo))
        }
    }
    
    const attachPromocode = async() => {
        const data = await attachPromocodeToBasket({promocode: promocode.code});

        if(data.message === PromocodeStates.VALID) {
            displayAttachedPromocode(structuredClone(data), structuredClone(defaultPromocodeInfo))
        }
        else {
            displayNotValidPromocode(data.message)
        }
    }

    const displayNotValidPromocode = (promocodeState) => {
        setPromocode((prev) => {
            const newPromocode = structuredClone(prev);
            newPromocode.state = promocodeState;
            return newPromocode;
        })
    }

    const displayAttachedPromocode = (data, defaultPromocodeInfo) => {
        setAppliedPromocode(data)
        setPromocode(defaultPromocodeInfo)
    }

    const removeAppliedPromocode = async () => {
        const {status} = await removePromocodeFromBasket();
        if(status === 200) {
            setAppliedPromocode(null)
            setPromocode(structuredClone(defaultPromocodeInfo))
        }
    }

    const removePromocode = () => {
        setPromocode(structuredClone(defaultPromocodeInfo))
    }

    const updatePromocodeCode = (text) => {
        setPromocode((prev) => {
            const newPromocode = structuredClone(prev);
            newPromocode.code = text;
            return newPromocode;
        })
    }

    const PromocodeStates = Object.freeze({
        VALID: "valid",
        EXPIRED: "expired",
        NOT_STARTED: "not_started",
        ALREADY_USED: "already_used",
        NOT_FOUND: "not_found",
    });

    const PromocodeStateMessage = Object.freeze({
        [PromocodeStates.VALID] : t("This promocode is applyed"),
        [PromocodeStates.EXPIRED] : t("This promocode is expired"),
        [PromocodeStates.NOT_STARTED] : t("This promocode hasn't started yet"),
        [PromocodeStates.ALREADY_USED] : t("This promocode is used"),
        [PromocodeStates.NOT_FOUND] : t("This promocode isn't found"),
    })

    return (
        <PromocodeContext.Provider
            value = {{
                promocode,
                attachPromocode,
                removePromocode,
                updatePromocodeCode,
                appliedPromocode,
                PromocodeStates,
                PromocodeStateMessage,
                removeAppliedPromocode,
                fetchAttachedPromocode,
                displayNotValidPromocode
            }}
        >
            {children}
        </PromocodeContext.Provider>
    )
}