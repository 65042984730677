import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PromocodeContext } from "../../../contexts/PromocodeContext";
import { XClose3SVG } from "../../../images/SVGAssets";
import WithTooltip from "../../WithTooltip/WithTooltip";
import PromocodeTooltipContent from "./PromocodeTooltipContent";
function AppliedPromocode() {
    const {appliedPromocode, removeAppliedPromocode} = useContext(PromocodeContext)
    const {t} = useTranslation()

    if(appliedPromocode) {
        return (
            <WithTooltip
                position="right"
                content={<PromocodeTooltipContent appliedPromocode={appliedPromocode}/>}
                wrapperClasses="mt-5"
            >
                <div
                    className="bg-blue-lidabro-promocode border border-blue-300 rounded-lg w-fit px-2 mt-2 flex justify-center items-center gap-x-1"
                >
                    <span
                        className="font-secondary text-blue-lidabro-error text-[14px]"
                    >
                        {t("Promocode")}:
                    </span>
                    <span className="font-secondary text-blue-lidabro-error text-[14px]">
                        {appliedPromocode.code}
                    </span>
                    <button
                        onClick={removeAppliedPromocode}
                    >
                        <XClose3SVG
                            width="15"
                            height="15"
                            color={"#175CD3"}
                        />
                    </button>
                    
                </div>
            </WithTooltip>
        )
    }
    
    return null;
}

export default AppliedPromocode;